<template>
     <!-- <Xuehua ref="xh"> -->
  <div>
    <div class="page">
      <div class="welcome-page">
        <div class="form-logo animated bounce hinge c2" style="margin: 10% auto">
          <img src="../assets/pictures/logo.png" />
        </div>
        <div class="wel-middle">
          <van-button color="linear-gradient(to right, #3270e0, #3653ea)"  to="/login" block>{{ $t("Login") }}</van-button>
          <van-button color="linear-gradient(to right, #3270e0, #3653ea)"  to="/regist" block>{{ $t("Register") }}</van-button>
          <!-- <div class="form-link">
            <div>
              {{ $t("Don’t have an account yet?") }}
              <router-link class="text-yellow" to="/regist">{{ $t("Register") }}</router-link>
            </div>
            <div>
              {{ $t("Download") }}
              <a :href="download_url_android" class="text-yellow" target="_blank">{{
                $t("Android APP")
              }}</a>
              {{ $t("或") }}
              <a :href="download_url_ios" class="text-yellow" target="_blank">{{
                $t("IOS APP")
              }}</a>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <StartupPage ref="StartupPage"></StartupPage>
  </div>
     <!-- </Xuehua> -->
</template>
<script>
// import Xuehua from "@/components/Xuehua";
import StartupPage from "@/components/StartupPage";
import helper from "@/utils/helper";
export default {
  components: { StartupPage },
  data() {
    return {
      download_url: "",
      download_url_android: "",
      download_url_ios: "",
      lang:''
    };
  },
  methods: {
    changeLocale(lang) {
          this.$i18n.locale = lang
          helper.setLang(lang)
          let token = helper.getToken();
          console.log(token)
          if(token !== '' && token !== null && token !== undefined){
              this.$axios.post(this.$api_url.set_lang, {lang: lang})
              .then((res) => {
                console.log(res.data)
              });
          }
      }
  },
  mounted() {
    this.download_url = localStorage.getItem("app.download_url") || "#";
    this.download_url_android =
    localStorage.getItem("app.download_url_android") || "#";
    this.download_url_ios = localStorage.getItem("app.download_url_ios") || "#";
    this.$refs.StartupPage.show();

    this.lang = this.$route.query.lang;
    if(this.lang){
      console.log(this.lang)
      this.changeLocale(this.lang);
    }
    // this.$refs.xh.show();
  },
  created() {},
};
</script>

<style scoped>
::v-deep.startupPage-wapper {
  z-index: 1;
}
.page{
    position: relative;
    z-index: 2;
}
.form-logo{
  text-align: center;
}
.form-logo img{
  height: 40px;
}
.wel-middle{
  padding: 0 15%;
}
.wel-middle ::v-deep .van-button{
  margin-bottom: 15px;
}
.form-link{
  text-align: center;
  font-size: 14px;
  line-height: 2;
}
.welcome-page .form-logo {
  padding-top: 40px;
}


</style>
